.container {
    text-align: center;
    font-family: sans-serif;
    width: 90%;
    margin: auto;
}

h1 {
    margin-bottom: 0;
    font-weight: normal;
}

h3, h4 {
    margin: 0;
}